// Object - project - btn

.btn-box-highschool {
    display: flex;
    justify-content: center;
    margin-top: _vw(33, 1366);
    @include _mq-up(xxl) {
        margin-top: 33px;
    }
    @include _mq-down(sm) {
        margin-top: _vw(20, 375);
    }
}

.btn-box {
    font-size: _vw(14, 1366);
    display: inline-block;
    min-width: _vw(215, 1366);
    min-height: _vw(50, 1366);
    line-height: 1.4286; //calc(14/20)
    text-align: center;
    padding: _vw(15, 1366);
    position: relative;
    box-sizing: border-box;
    @include _mq-up(xxl) {
        font-size: 14px;
        min-width: 215px;
        min-height: 50px;
        padding: 15px;
    }
    @include _mq-down(sm) {
        font-size: 14px;
        width: 215px;
        height: 50px;
        padding: 15px;
    }
    &.coler-brand {
        color: $color-brand;
    }
    &.coler-white {
        color: #fff;
    }
    &.arrow-left::after {
        content: '';
        background: url("../../assets/img/common/arrow-r-brand.svg") no-repeat;
        transition: 0.2s;
        position: absolute;
        left: _vw(18, 1366);
        top: 0;
        bottom: 0;
        margin: auto 0;
        width: _vw(5, 1366);
        height: _vw(9, 1366);
        @include _mq-up(xxl) {
            left: 18px;
            width: 5px;
            height: 9px;
        }
        @include _mq-down(sm) {
            left: _vw(18, 375);
            width: _vw(5, 375);
            height: _vw(9, 375);
        }
    }
    &:hover {
        &.arrow-left::after {
            left: _vw(20, 1366);
            @include _mq-up(xxl) {
                left: 20px;
            }
            @include _mq-down(sm) {
                left: _vw(20, 375);
            }
        }
    }
    &.arrow-left.arrow-white::after {
        background: url("../../assets/img/common/arrow-r-white.svg") no-repeat;
    }
    &.bg-white {
        background-color: #fff;
    }
    &.bg-brand {
        background-color: $color-brand;
    }
    &.bg-gradation {
        background: linear-gradient(135deg, $color-brand-accent 0%, $color-brand 100%);
    }
}

/**
* トップページ用
**/
.top-btn-box {
    font-size: _vw(14);
    display: block;
    width: _vw(250);
    min-height: _vw(50);
    line-height: 1.4286; //calc(14/20)
    text-align: center;
    //padding: _vw(15, 1366);
    position: relative;
    letter-spacing: 0.05em;
    display: flex;
    justify-content: center;
    align-items: center;
    @include _mq-up(xxl) {
        font-size: 14px;
        width: 250px;
        min-height: 50px;
        //padding: 15px;
    }
    @include _mq-down(sm) {
        font-size: 14px;
        width: 250px;
        min-height: 50px;
        //padding: _vw(15, 375);
    }
    &.coler-brand {
        color: $color-brand;
    }
    &.coler-white {
        color: #fff;
    }
    &.arrow-left::after {
        content: '';
        background: url("../../assets/img/common/arrow-r-brand.svg") no-repeat;
        transition: 0.2s;
        position: absolute;
        left: _vw(18);
        top: 0;
        bottom: 0;
        margin: auto 0;
        width: _vw(5);
        height: _vw(9);
        @include _mq-up(xxl) {
            left: 18px;
            width: 5px;
            height: 9px;
        }
        @include _mq-down(sm) {
            left: 10px;
            width: 5px;
            height: 9px;
        }
    }
    @include _mq-up(md) {
        &:hover {
            &.arrow-left::after {
                left: _vw(20);
                /*@include _mq-up(xxl) {
                    left: 20px;
                }
                @include _mq-down(sm) {
                    left: 20px;
                }*/
            }
        }
    }
    &.arrow-left.arrow-white::after {
        background: url("../../assets/img/common/arrow-r-white.svg") no-repeat;
    }
    &.bg-white {
        background-color: #fff;
    }
    &.bg-brand {
        background-color: $color-brand;
    }
    &.bg-gradation {
        background: linear-gradient(135deg, $color-brand-accent 0%, $color-brand 100%);
    }
    &.target-r-white {
        &::after {
            content: '';
            width: _vw(16);
            height: _vw(13);
            background: url("../../assets/img/common/icon_target_white.svg") center center / contain no-repeat;
            position: absolute;
            right: _vw(15);
        }
    }
}

.top-btn-column {
    display: flex;
    justify-content: center;
    @include _mq-up(md) {
        .top-btn-box + .top-btn-box {
            margin-left: _vw(50);
        }
    }
    @include _mq-down(md) {
        .top-btn-box + .top-btn-box {
            margin-left: _vw(10);
        }
    }
}

/**
* 下層ページ用
**/
.page-btn-box {
    font-size: 14px;
    display: block;
    width: 250px;
    min-height: 50px;
    line-height: 1.4286; //calc(14/20)
    text-align: center;
    //padding: _vw(15, 1366);
    position: relative;
    letter-spacing: 0.05em;
    display: flex;
    justify-content: center;
    align-items: center;
    /*@include _mq-up(xxl) {
        font-size: 14px;
        width: 250px;
        min-height: 50px;
        //padding: 15px;
    }
    @include _mq-down(sm) {
        font-size: 14px;
        width: _vw(250, 375);
        min-height: _vw(50, 375);;
        //padding: _vw(15, 375);
    }*/
    &.coler-brand {
        color: $color-brand;
    }
    &.coler-white {
        color: #fff;
    }
    &.arrow-left::after {
        content: '';
        background: url("../../assets/img/common/arrow-r-brand.svg") no-repeat;
        transition: 0.2s;
        position: absolute;
        left: 18px;
        top: 0;
        bottom: 0;
        margin: auto 0;
        width: 5px;
        height: 9px;
        @include _mq-up(xxl) {
            left: 18px;
            width: 5px;
            height: 9px;
        }
        /*@include _mq-down(sm) {
            left: _vw(18, 375);
            width: _vw(5, 375);
            height: _vw(9, 375);
        }*/
    }
    &:hover {
        &.arrow-left::after {
            left: 20px;
            /*@include _mq-up(xxl) {
                left: 20px;
            }
            @include _mq-down(sm) {
                left: 20px;
            }*/
        }
    }
    &.arrow-left.arrow-white::after {
        background: url("../../assets/img/common/arrow-r-white.svg") no-repeat;
    }
    &.bg-white {
        background-color: #fff;
    }
    &.bg-brand {
        background-color: $color-brand;
    }
    &.bg-gradation {
        background: linear-gradient(135deg, $color-brand-accent 0%, $color-brand 100%);
    }
    &.target-r-white {
        &::after {
            content: '';
            width: 16px;
            height: 13px;
            background: url("../../assets/img/common/icon_target_white.svg") center center / contain no-repeat;
            position: absolute;
            right: 15px;
        }
    }
}

.page-btn-center {
    margin-top: 120px;
    .page-btn-box {
        margin: 0 auto;
    }
    @include _mq-down(md) {
        margin-top: 60px;
    }
}

.page-btn-column {
    display: flex;
    justify-content: center;
    @include _mq-up(md) {
        .page-btn-box + .page-btn-box {
            margin-left: 50px;
        }
    }
    @include _mq-down(md) {
        display: block;
        .page-btn-box {
            margin-right: auto;
            margin-left: auto;
        }
        .page-btn-box + .page-btn-box {
            margin-top: 30px;
        }
    }
}
