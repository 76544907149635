// ==========================================================
//  Layout - main
// ==========================================================
.l-wrap {
    @include _wrapper($wrapper-width);
}
.l-wrap-wide {
    @include _wrapper($wrapper-width-wide);
}
.l-wrap-max {
    @include _wrapper-fluid($wrapper-width-max);
}

.l-wrap-fluid {
    width: 100%;
    max-width: 900px;
    margin-right: auto;
    margin-left: auto;
    @include _mq-down(md) {
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
    }
}

.l-wrap-inr {
    width: 100%;
    max-width: 1320px;
    margin: 0 auto;
    padding-left: 30px;
    padding-right: 30px;
    @include _mq-down(md) {
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
    }
}
.l-page {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    @include _mq-down(md) {
        max-width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }
}
