// Object - project - loading
/*
.loader-cover {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    background-color: #fff;
    z-index: 9999
}

.loader {
    font-size: 10px;
    margin: 50px auto;
    text-indent: -9999em;
    width: 8em;
    height: 8em;
    border-radius: 50%;
    background: $color-brand;
    background: -moz-linear-gradient(left, $color-brand 10%, rgba(255, 255, 255, 0) 42%);
    background: -webkit-linear-gradient(left, $color-brand 10%, rgba(255, 255, 255, 0) 42%);
    background: -o-linear-gradient(left, $color-brand 10%, rgba(255, 255, 255, 0) 42%);
    background: -ms-linear-gradient(left, $color-brand 10%, rgba(255, 255, 255, 0) 42%);
    background: linear-gradient(to right, $color-brand 10%, rgba(255, 255, 255, 0) 42%);
    position: relative;
    -webkit-animation: load3 1.4s infinite linear;
    animation: load3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto
}

.loader:before {
    width: 50%;
    height: 50%;
    background: $color-brand;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: ''
}

.loader:after {
    background: #fff;
    width: 80%;
    height: 80%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0
}

@-webkit-keyframes load3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }

}

@keyframes load3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }

}
*/
