// 使用例
//.hoge {
//  @include _hover {
//    color: red;
//  }
//}

@mixin _hover { //ホバーが効くブラウザのみ
  @media (hover: hover) {
    &:hover {
      @content;
    }
  }
}

@mixin _nohover { //ホバーが効かないブラウザのみ
  @media (hover: none) {
    &:hover {
      @content;
    }
  }
}
