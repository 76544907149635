// ============================================
// header
// ============================================
.global-header {
    position: fixed;
    z-index: 999;
}

.global-header-inr {
    position: fixed;
    left: 0;
    right: 0;
    margin: auto;
    padding-top: 23px;
    padding-left: 28px;
    box-sizing: border-box;
    z-index: 1;
    img {
        display: block;
    }
    @include _mq-down(md) {
        padding-top: 15px;
        height: 70px;
    }
    @include _mq-down(sm) {
        background: rgba(255,255,255,1);
        padding-top: 18px;
        padding-left: 15px;
        height: 60px;
        img {
            height: 25px;
        }
    }
}

.header-logo {
    width: 459px; //固定
    //display: none;
    a {
        display:block;
    }
    @include _mq-down(md) {
        width: 274px;
        display: block;
        img {
            width: 100%;
            height: 100%;
        }
    }
}

.sp-ocbtn {
    @include _mq-up(md) {
        display: none;
    }
    position: absolute;
    top: 0;
    right: 60px;
    width: 60px;
    height: 60px;
    background: $color-brand;
    display: flex;
    justify-content: center;
    align-items: center;
    a::after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 0 0 10px;
        border-color: transparent transparent transparent rgba(0,0,0,0.3);
        position: absolute;
        left: 0;
        bottom: 0;
    }
    img {
        width: 47px;
        height: auto;
    }
}

.header-menu-cover {
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
    z-index: 9998;
    @include _mq-down(md) {
        position: absolute;
        top: 60px;
        right: 0;
        overflow: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        height: calc(100vh - 58px);
        pointer-events: none;
        &.active {
            pointer-events: auto;
        }
    }
}

.Active {
    .header-menu-cover {
        pointer-events: auto;
    }
}

.header-menu-lists {
    display: flex;
    position: absolute;
    top: 0;
    right: 70px;
    background: $color-brand;
    background: linear-gradient(90deg, $color-brand 0%, $color-brand-grade 100%);
    @include _mq-down(md) {
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.3s ease-out, visibility 0.3s ease-out;
        width: 280px;
        flex-wrap: wrap;
        top: 68px;
        right: 0;
        box-shadow: -5px 5px 5px rgba(0, 0, 0, .3);
        position: static;
    }
}

@include _mq-down(md) {
    &.Active {
        .header-menu-lists {
            visibility: visible;
            opacity: 1;
        }
    }
}

.header-menu-list {
    position: relative;
    width: 140px;
    a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 70px;
        color: #fff;
        font-size: 1.2rem;
        letter-spacing: 0.05em;
        padding: 0 1.8em;
        position: relative;
        cursor: pointer;
        &:hover {
            @include _mq-up(md) {
                opacity: .6;
            }
        }
        @include _mq-down(md) {
            flex-direction: column;
            text-align: center;
            padding: 0;
            width: 70px;
            font-size: .8rem;
        }
        &:after {
            content: "";
            display: block;
            width: 1px;
            height: 60%;
            background-color: rgba(0, 0, 0, .3);
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            @include _mq-down(md) {
                display: none;
            }
        }
    }
}

.header-menu-list {
    &.doc {
        a {
            position: relative;
            background: url("../../assets/img/common/icon_document.svg") left 20px center / 26px 20px no-repeat;
        }
        span {
            position: absolute;
            right: 27px;
        }
    }
    &.oc {
        a {
            position: relative;
            background: url("../../assets/img/common/icon_oc.svg") left 20px center / 26px 20px no-repeat;
        }
        span {
            position: absolute;
            right: 24px;
            line-height: 1.3;
        }
    }
    &.choi {
        a {
            position: relative;
            background: url("../../assets/img/common/icon_choi.svg") left 18px center / 26px 20px no-repeat;
        }
        span {
            position: absolute;
            right: 15px;
        }
    }
    &.contact {
        @include _mq-down(md) {
            display: none;
        }
    }
    &.square {
        width: 70px;
        a {
            flex-shrink: 0;
            width: 70px;
            padding: 0;
            position: relative;
            span {
                font-size: 1.0rem;
                transform: scale(0.9);
                position: absolute;
                bottom: 14px;
                @include _mq-down(md) {
                    //border-top: 1px solid rgba(0, 0, 0, .1);
                    font-size: 1.0rem;
                    transform: scale(0.8);
                }
            }
        }
        &.access a {
            background: url("../../assets/img/common/icon_access.svg") center top 15px / 25px 22px no-repeat;
        }
        &.contact a {
            background: url("../../assets/img/common/icon_contact.svg") center top 17px / 24px 18px no-repeat;
            &:after {
                @include _mq-up(md) {
                    display: none;
                }
            }
        }
    }
    @include _mq-down(md) { //すべてsquareブロックになる
        width: 25%;
        border-right: 1px solid rgba(0, 0, 0, .1);
        a span {
            font-size: 1.0rem;
            transform: scale(0.8);
            letter-spacing: 0.1em;
        }
        &.doc, &.oc, &.choi {
            a {
                background-position: center top 15px;
                background-size: 28px 22px;
            }
            span {
                position: absolute;
                right: 0;
                left: 0;
                bottom: 14px;
                margin: 0 auto;
            }
        }
        &.oc {
            a {
                background-position: center top 12px;
            }
            span {
                line-height: 1.3;
                bottom: 8px;
            }
        }
        &.line a {
            background: url("../../assets/img/common/icon_line.svg") center top 14px / 22px 22px no-repeat;
        }
        &.insta a {
            background: url("../../assets/img/common/icon_instagram.svg") center top 14px / 21px 21px no-repeat;
        }
        &.tiktok a {
            background: url("../../assets/img/common/icon_tiktok.svg") center top 13px / 18px 21px no-repeat;
        }
        &.youtube a {
            background: url("../../assets/img/common/icon_youtube.svg") center top 16px / 25px 17px no-repeat;
        }
        &.line, &.insta, &.tiktok, &.youtube {
            border-top: 1px solid rgba(0, 0, 0, .1);
            span {
                position: absolute;
                right: 0;
                left: 0;
                bottom: 14px;
                margin: 0 auto;
            }
        }
    }
}

//ブラウザ幅によるヘッダー調整
@media all and (min-width:768px) and (max-width:1130px) {
    .header-menu-list.doc, .header-menu-list.choi{
        width: 70px;
        a {
            flex-direction: column;
            text-align: center;
            padding: 0;
            width: 70px;
            font-size: .8rem;
            background-position: center top 17px;
            span {
                position: absolute;
                bottom: 14px;
                right: auto;
                left: auto;
            }
        }
    }
    .header-menu-list.oc {
        width: 70px;
        a {
            flex-direction: column;
            text-align: center;
            padding: 0;
            width: 70px;
            font-size: .8rem;
            background-position: center top 13px;
            span {
                position: absolute;
                bottom: 9px;
                right: 0;
                left: 0;
                margin: 0 auto;
            }
        }
    }
}

@media all and (min-width:768px) and (max-width:930px) {
    .header-logo {
        width: 320px;
    }
}
