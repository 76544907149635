// ==========================================================
// 	Layout - main
// ==========================================================

@font-face {
  font-family: 'Lato';
  src: url("../../assets/font/Lato-Regular.woff");
  font-weight: regular;
}

@font-face {
  font-family: 'Lato';
  src: url("../../font/Lato-Bold.woff");
  font-weight: bold;
}

@font-face {
  font-family: 'NotoSansJP';
  src: url("../../font/NotoSansJP-Regular.woff");
  font-weight: regular;
}

@font-face {
  font-family: 'NotoSansJP';
  src: url("../../font/NotoSansJP-Bold.woff");
  font-weight: bold;
}

body:not(#home):not(#subject) main {
    //background: #f0f0f0;
}
@include _mq-down(md) {
    body:not(#home):not(#subject) main {
        background: #fff;
    }
    body:not(#home) {
        .page-Content-block {
            padding: 45px 0 140px;
        }
        .page-Content-block {
            &.Support, &.Education, &.Subject, &.Course {
                padding-top: 0;
            }
        }
    }
}

// responsive display - utility //抜粋//
.u-display {
    &-sm {
        @include _mq-up(sm) {
            display: none !important;
        }
    }
    &-md {
        @include _mq-up(md) {
            display: none !important;
        }
    }
}

.u-hide {
    &-sm {
        @include _mq-down(sm) {
            display: none !important;
        }
    }
    &-md {
        @include _mq-down(md) {
            display: none !important;
        }
    }
}

@include _mq-up(md) { //PC時telリンク機能停止
    a[href*="tel:"] {
        text-decoration: none;
        cursor: default;
        pointer-events: none
    }
}

/*object-fit-image*//*imgタグにクラス付与*/
.object-fit {
  object-fit: cover;
  /*object-position: bottom;*/
  font-family: 'object-fit: cover;';
  /*font-family: 'object-fit: cover; object-position: bottom;';*/
}

.u-palt {
    font-feature-settings: "palt" 1;
}

.u-d-in {
    display: inline-block;
}

.u-d-block {
    display: block;
}
.u-d-inblock {
    display: inline-block;
}
.u-d-in-sm {
    @include _mq-down(sm) {
        display: inline-block;
    }
}
