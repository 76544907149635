// Object - project - title
/*
.sectionTit {
    font-size: 20px;
    text-align: center;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    margin-bottom: 50px;
}
*/
