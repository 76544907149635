//Noto Sans JP
//@import url(https://fonts.googleapis.com/earlyaccess/notosansjapanese.css);
// @import url(//fonts.googleapis.com/css?family=Montserrat);

// よく使うGoogleFonts
// Noto Sans JP
// @import url(//fonts.googleapis.com/earlyaccess/notosansjp.css);
/*@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap);*/
//
// Montserrat (Gothemの代替)
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap);
//
// Lato (Gill Sansの代替)
// @import url(//fonts.googleapis.com/css?family=Lato);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap);
//
// Poppings
// @import url(https://fonts.googleapis.com/css?family=Poppins:600);
//
// 組み合わせて指定する場合
//@import url(//fonts.googleapis.com/css?family=Lato|Noto+Sans+JP);
