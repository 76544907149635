// Object - project - tab

.selectTab {
    display: flex;
    /*gap: 5px;*/
    li {
        width: 100%;
        text-align: center;
        cursor: pointer;
        span {
            display: block;
            border: 1px solid #ccc;
            width: 100%;
            height: 50px;
            line-height: 50px;
        }
        &.select {
            span {
                /*border-bottom: 0;*/
            }
        }
    }
}
